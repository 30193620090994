<template>
	<!-- 전체 콘텐츠 영역 -->
	<div class="main-contents">
		<div class="mem_container resume hr_evl">
			<div class="mem_info_contents">
				<div class="mem_contents_header">
					<div class="title-txt">인재평가하기</div>
				</div>
				
				<tecInfo v-model="data" type="clientEval" :tecMberSeq="forwardData.indMberSeq" />
				
				<!-- 인재평가 항목 -->
				<div class="Board type3">
					<table class="Board_type3 hr_evl">
						<colgroup>
							<col width="13%">
							<col width="10%">
							<col width="57%">
							<col width="4%">
							<col width="4%">
							<col width="4%">
							<col width="4%">
							<col width="4%">
						</colgroup>
						<thead>
							<tr>
								<th colspan="2">평가항목</th>
								<th>내용</th>
								<th class="rank">A<span @click="tempScore('10')">10점</span></th>
								<th class="rank">B<span @click="tempScore('9')">9점</span></th>
								<th class="rank">C<span @click="tempScore('8')">8점</span></th>
								<th class="rank">D<span @click="tempScore('7')">7점</span></th>
								<th class="rank">E<span @click="tempScore('6')">6점</span></th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="row in evalItemList" :key="row.evalItemSeq">
								<td class="score" :rowspan="row.mergeCnt" v-if="row.mergeCnt == row.mergeChkCnt">{{row.evalItemDivNm}}</td>
								<td class="score">{{row.evalItemNm}}</td>
								<td>{{row.evalItemCont}}</td>
								<td class="score" v-for="rdoIdx in 5" :key="rdoIdx">
									<input type="radio" :name="'rdo'+row.evalItemSeq" v-model="row.evalScore" :id="'rdo'+row.evalItemSeq+rdoIdx" :value="11 - rdoIdx" :disabled="evalTab.modYn != 'Y'">
									<label :for="'rdo'+row.evalItemSeq+rdoIdx">
										<span></span>
									</label>
								</td>
							</tr>

							<!-- 평가의견 -->
							<tr>
								<td class="score" rowspan="4">평가의견</td>
								<td class="score">태도측면</td>
								<td class="score" colspan="6"><InputComp classNm="evl" type="textarea" v-model="evalTab.attitudOpinCont" /></td>
							</tr>
							<tr>
								<td class="score">업무능력측면</td>
								<td class="score" colspan="6"><InputComp classNm="evl" type="textarea" v-model="evalTab.workAbilOpinCont" /></td>
							</tr>
							<tr>
								<td class="score">개발기술측면</td>
								<td class="score" colspan="6"><InputComp classNm="evl" type="textarea" v-model="evalTab.techIntegAbilOpinCont" /></td>
							</tr>
							<tr>
								<td class="score">&starf;종합의견&starf;</td>
								<td class="score" colspan="6"><InputComp classNm="evl" type="textarea" v-model="evalTab.overallOpinCont" placeholder="‘종합의견’은 다른 기업에 노출되는 내용입니다. 신중한 작성을 부탁드리며, 감정적인 표현은 자제 해 주시기 바랍니다!" /></td>
							</tr>
						</tbody>
					</table>
				</div>
				<!-- 하단 버튼 -->
				<img src="/images/human_evl_caution.png" class="human_evl_caution" alt="인재평가수정시" v-if="evalTab.modYn == 'Y'"/>
				<div class="select_btn">
					<div class="btn_01" @click="$router.go(-1);">취소</div>
					<div class="btn_02" @click="evalEnd()" v-if="evalTab.modYn == 'Y'" :style="evalTab.evalTabSeq ? 'background-color: #FF681D;' : ''">
						{{evalTab.evalTabSeq ? '수정하기' : '평가완료'}}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import tecInfo from "@/components/highpro/TecInfo.vue";

export default {
	data() {
		return {
			forwardData : {},

			evalTab : {
				attitudOpinCont : '',
				workAbilOpinCont : '',
				techIntegAbilOpinCont : '',
				overallOpinCont : '',
				modYn : 'Y',
			},
			evalItemList : [],
			
			data : {},
			
			chkMberSeq : '',
			chkMberStatus : ''
		}
	},

	components : {
		tecInfo
	},

	beforeMount() {

		this.chkMberSeq = this.$route.query.indMberSeq;
		this.getDcoPmgMberStatus();

		if(this.$route.query.indMberSeq) this.forwardData = this.$route.query;
		else if(this.$route.params) this.forwardData = this.$route.params;

		this.getDcoPmgEvalItemList();
	},

	methods : {

		getDcoPmgMberStatus(){
			this.$.httpPost('/api/prj/dco/pmg/getDcoPmgMberStatus', {chkMberSeq : this.chkMberSeq})
				.then((res) => {
					this.chkMberStatus = res.data.chkMberStatus.mberStatusCd;

					if(this.chkMberStatus === '91'){
						this.$.popup('/dco/pmg/prj/PRJ304P01')
						.then(()=> {
							this.$router.go(-1);
						})
					
					}
				})
		},

		getDcoPmgEvalItemList() {
			this.$.httpPost('/api/prj/dco/pmg/getDcoPmgEvalItemList', this.forwardData)
				.then((res) => {
					if(res.data.evalTab) this.evalTab = res.data.evalTab;

					this.evalItemList = res.data.evalItemList;
				})
		},

		evalEnd() {
			var param = this.evalTab;
			param.indMberSeq = this.forwardData.indMberSeq;
			param.projectSeq = this.forwardData.projectSeq;
			param.evalItemList = this.evalItemList;

			//console.log('EVAL END PARAM', param);

			this.$.httpPost('/api/prj/dco/pmg/setDcoPmgEvalList', param)
				.then(() => {
					alert('평가완료 되었습니다.');
					this.$router.go(-1);
				})
		},

		tempScore(score) {
			for(var i in this.evalItemList) {
				this.evalItemList[i].evalScore = score;
			}

			this.$forceUpdate();
		}
	}
}
</script>